<template>
  <v-row justify="center" class="ma-0">
    <v-card
        class="w-75 d-flex justify-center align-center position-relative bg-transparent card rounded-xl"
        height="140"
        :href="`${content.url}`"
        target="_blank"
        hover
    >
      <v-img
          class="position-absolute w-100 h-100 bg-white"
          style="z-index: -1; opacity: 0.7;"
          :src="img"
          cover
      />
      <v-card-subtitle
          v-if="content.text.top"
          class="position-absolute"
          :style="{ color: content.color, backgroundColor: content.bgColor }"
          style="top: 10px;"
      >
        {{content.text.top}}
      </v-card-subtitle>
      <v-card-subtitle
          v-if="content.text.center"
          :style="{ color: content.color, backgroundColor: content.bgColor }"
      >
        {{content.text.center}}
      </v-card-subtitle>
      <v-card-subtitle
          v-if="content.text.bottom"
          class="position-absolute"
          :style="{ color: content.color, backgroundColor: content.bgColor }"
          style="bottom: 10px;"
      >
        {{content.text.bottom}}
      </v-card-subtitle>
    </v-card>
  </v-row>
</template>

<style scoped>
.v-card-subtitle {
  color: #343434;
  opacity: 1 !important;
  padding: 4px 15px !important;
  box-shadow: rgba(33, 33, 33, 0.52) 0 0 10px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
}
</style>

<script>
export default {
  props: ["content"],
  data: () => ({
    img: null
  }),
  async created() {
    this.img = await this.fetchImage(this.$route.params.id,this.content.img)
  }
}
</script>
