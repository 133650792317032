<template>
  <v-row justify="center" class="ma-0">
    <v-card
        flat
        class="w-75 bg-transparent"
    >
      <v-card-title
          class="pa-0 pl-1"
          style="font-size: 30px; font-weight: 300"
      >
        {{content.title}}
      </v-card-title>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: ["content"]
}
</script>