<template>
  <v-card flat class="w-100 d-flex align-center flex-column bg-transparent">
    <v-avatar
        color="grey-darken-1"
        class="mt-15 elevation-2"
        size="70"
    >
      <v-img :src="avatar" />
    </v-avatar>
    <v-card-title class="text-subtitle-1 font-weight-medium">
      {{userDetails.userName}}
    </v-card-title>
    <div class="pa-1 w-66 h-auto d-flex justify-center align-center bg-transparent">
      <a v-for="(link, i) in userDetails.links"
         :key="i"
         :href="link.url"
         target="_blank"
         class="btn mx-1 mx-sm-3 elevation-2 rounded-sm pa-1 d-flex align-center justify-center text-decoration-none"
      >
        <v-icon style="color: #212121" size="28">fa-brands fa-{{link.icon}}</v-icon>
      </a>
    </div>
  </v-card>
</template>

<script>
export default  {
  props: ["userDetails"],
  data: () => ({
    avatar: null
  }),
  async created() {
    this.avatar = await this.fetchImage(this.$route.params.id, "avatar");
  }
}
</script>

<style scoped>
a {
  opacity: 1;
  transition: opacity 0.5s ease;
}
a:hover {
  opacity: 0.5;
}
</style>