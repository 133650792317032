import { createRouter, createWebHistory } from "vue-router";
import App from "@/App.vue";

const router = createRouter({
    routes: [
        {
            path: "/:id",
            name: "Home",
            component: App,
            props: (route) => ({ id: route.params.id }),
            meta: { title : "さんのリンク" }
        }
    ],
    history: createWebHistory()
});

router.beforeEach((to) => {
    document.title = (to.params.id +  to.meta?.title ?? "このユーザーは存在しません") + " | vfan-link"
})

export default router;