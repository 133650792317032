<template>
  <v-row justify="center" class="ma-0">
    <v-card
        class="w-75 d-flex justify-center align-center position-relative card rounded-xl"
        :style="{ backgroundColor: content.bgColor}"
        :href="content.url"
        target="_blank"
        hover
        height="120"
    >
      <v-icon
          class="pa-8 d-flex justify-center align-center rounded-lg ml-5 elevation-2"
          :color="content.iconColor"
          :style="{ backgroundColor: content.iconBgColor }"
          size="38"
      >
        fa-brands fa-{{content.brand}}
      </v-icon>
      <v-card-text
          :style="{fontSize: 17 + 'px', fontWeight: 300, color: '#212121'}"
      >
        {{content.text}}
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: ["content"]
}
</script>