import axios from "axios";

export default async (userId, imageId) => {
    let data;

    await axios.get(`https://store.vfan.link/${userId}/${imageId}.png`, { responseType: "blob"})
        .then(res => {
            let url = window.URL || window.webkitURL;

            data = url.createObjectURL(res.data)
        })

    return data;
}