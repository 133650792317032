import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from "@/router";
import fetchImage from "@/plugins/fetch-image";

loadFonts()

createApp(App)
    .use(vuetify)
    .use(router)
    .mixin({
        methods: {
            fetchImage
        }
    })
    .mount('#app')
