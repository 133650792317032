<template>
  <v-app class="bg-grey-lighten-3">
    <v-img
        flat
        cover
        class="h-100 position-fixed w-100"
        style="opacity: 0.2; filter: blur(8px);"
        indeterminate
        :src="bgImage"
    />
    <v-row justify="center" class="ma-0 w-auto">
      <v-card flat class="position-relative mx-width h-100 elevation-1 d-flex bg-transparent rounded-0">
        <v-app-bar-nav-icon class="position-absolute rounded-sm" style="right: 0; z-index: 10;" color="grey-darken-2"/>
        <v-main class="w-100 h-auto">
          <div v-if="!notFound" class="pb-10">
            <div class="ma-0 mx-width w-100 position-fixed bg-grey-5" style="height: 100vh; left: auto; right: auto;">
              <v-img
                  flat
                  cover
                  class="h-100 w-100 bg-white bg-black mx-width"
                  style="opacity: 0.2; left: 0; top: 0"
                  blur="4"
                  :src="bgImage"
              />
            </div>

            <!--  #    UserInfo   #  -->
            <UserInfo
                class="m-sm-16 mb-10"
                v-if="user"
                :user-details="user"
            />

            <!--  #    Links   #  -->
            <div
                v-for="(content, i) in contents"
                :key="i"
            >
              <ImageLink
                  v-if="content.type === 'image-link'"
                  :class="`mb-${content.margin ?? 10}`"
                  :content="content"
              />
              <IconLink
                  v-if="content.type === 'icon-link'"
                  :class="`mb-${content.margin ?? 10}`"
                  :content="content"
              />
              <SectionTitle
                  v-if="content.type === 'section-title'"
                  :class="`mb-${content.margin ?? 10}`"
                  :content="content"
              />
              <TextSection
                  v-if="content.type === 'text-section'"
                  :class="`mb-${content.margin ?? 10}`"
                  :content="content"
              />
            </div>
          </div>
          <NotFound v-if="notFound"/>

          <!--  #    Footer   #  -->
          <v-footer
              height="20"
              class="pb-5 bg-transparent position-absolute text-center w-100"
              style="z-index: 0; bottom: 0"
          >
            <div
                class="px-4 py-2 bg-transparent text-center w-100"
                style="font-size: 12px;"
            >
              &copy; {{ new Date().getFullYear() }} <i><strong>vfan.link</strong> All Rights Reserved</i>
            </div>
          </v-footer>
        </v-main>
      </v-card>
    </v-row>
  </v-app>
</template>

<script>
import ImageLink from "@/components/link-component/ImageLink.vue";
import UserInfo from "@/components/UserInfo.vue";
import IconLink from "@/components/link-component/IconLink.vue";
import SectionTitle from "@/components/link-component/SectionTitle.vue";
import TextSection from "@/components/link-component/TextSection.vue";
import axios from "axios";
import NotFound from "@/components/NotFound.vue";

export default {
  name: 'App',
  props: ["id"],
  components: {
    NotFound,
    TextSection,
    UserInfo,
    ImageLink,
    IconLink,
    SectionTitle
  },
  data: () => ({
    bgImage: null,
    user: null,
    contents: null,
    notFound: false
  }),
  async mounted() {
    await this.$router.isReady()
    const id = this.$route.params.id;
    axios.get(`https://store.vfan.link/${id}/data.json`)
        .then(async response => {
          this.user = response.data.user;
          this.contents = response.data.contents;
          this.bgImage = await this.fetchImage(id, "bg-image");
        })
        .catch(() => {
          this.notFound = true;
        });
  }
}
</script>

<style scoped>
.mx-width {
  max-width: 700px;
  width: 100%;
}
</style>
