// Styles
import '@fortawesome/fontawesome-free/css/all.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import {aliases} from "vuetify/iconsets/fa";
import {fa} from "vuetify/locale";

export default createVuetify({
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    icons: {
        defaults: "aw",
        aliases,
        sets: {
            fa,
        }
    }
})
