<template>
  <div class="w-100 h-100 pt-sm-16 bg-white flex-column justify-center d-flex align-center">
    <v-card
        class="w-75 h-75 pt-16"
    >
      <v-card-title style="font-size: 120px; color: #424242 !important;" class="py-10 text-center">
        404
      </v-card-title>
      <v-card-subtitle class="text-center mt-10" style="font-size: 23px; color: #424242 !important;">
        ページが見つかりませんでした
      </v-card-subtitle>
      <v-card-text class="text-center h-auto mt-10" style="font-size: 23px; color: #424242 !important; line-height: 30px">
        ユーザー名を確認後、再度アクセスください。
      </v-card-text>
    </v-card>
  </div>
</template>