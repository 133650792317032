<template>
  <v-row justify="center" class="ma-0">
    <v-card
        class="w-75 bg-transparent d-flex justify-center flex-column"
    >
      <v-card-title class="text-center">
        {{content.title}}
      </v-card-title>
      <div class="my-3 mx-1">
        <v-card-text
            class="text-center text-pre-wrap pa-1 px-5"
            v-for="(text, i) in content.text.split('\n')"
            :key="i"
        >
          {{text}}
        </v-card-text>
      </div>
    </v-card>
  </v-row>
</template>

<script>
export default {
  props: ["content"]
}
</script>